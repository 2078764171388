.selectUsersModal{
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    list-style-type: none;
    position: absolute;
    background: #fff;
    width: calc(100% - 84px);
    top: 40px;
    max-height: 150px;
    overflow-y: auto;
}

.selectUsersModal::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.selectUserItem{
    padding: 6px 0;
    cursor: pointer;
    border-bottom: 1px solid #f4f4f4;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 35%;
}

.selectUserItem:hover{
    background-color: #f1f1f1;
}