.Editor{
    width: 100%;
    position: relative;
    .bytemd-fullscreen{
        z-index: 100;
    }
    .bytemd{
        height: 100vh;
    }
    .bytemd-editor, .bytemd-preview{
        text-align: left;
    }
}
.bytemd-toolbar .bytemd-toolbar-right{
    .bytemd-toolbar-icon:nth-child(4){
        display: none;
        background-color: #000;
    }
}
.normal-btn{
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
    &:hover{
        color: #4096ff;
        border-color: #4096ff;
    }
}
.primary-btn{
    color: #fff;
    background-color: #1374f9;
    border: none;
    &:hover {
        color: #fff;
        background-color: #3e94fa;
    }
}

.icon, .icon-large{
    // width: 5%;
    width: 20px;
    height: 20px;
    padding: 2px;
    font-size: 12px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mask{
    width: 100%;
    height: 100vh;
    background-color: #00000043;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 222;   //  第二层

    display: flex;
    align-items: center;
    justify-content: center;
    // 设置
    .mask-container{
        width: 550px;
        background-color: #fff;
        position: relative;
        .close{
            width: 30px;
            height: 30px;
            position: absolute;
            right: 0;
            top: 0;
            border: none;
            background-color: #fff;
            cursor: pointer;
            transition: background-color 0.2s;
            &:hover{
                background-color: #0000001f;
            }
        }
        .mask-content{
            padding: 50px 0;
            min-height: 300px;
            box-sizing: border-box;
            h4{
                padding: 10px 0;
            }
            // 输入
            .form-item{
                width: 100%;
                height: 35px;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                .form-item-label{
                    width: 20%;
                    padding-right: 15px;
                    text-align: right;
                }
                .form-item-inner{
                    width: 80%;
                    height: 100%;
                    margin-right: 30px;
                    padding: 5px 10px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                }
                .form-item-input{
                    border: 1px solid #d9d9d9;
                    border-radius: 6px;
                    input{
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        font-size: 16px;
                        border: none;
                        &:focus{
                            outline: none;
                        }
                    }
                }
                .form-item-switch{
                    p{
                        margin: 0;
                        margin-left: 10px;
                        font-weight: 500;
                        transition: color 0.2s;
                        cursor: pointer;
                        &:hover{
                            color: #006391;
                        }
                    }
                }
            }
        }
        .mask-footer{
            height: 70px;
            padding: 0 25px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 25px;
            button{
                width: 100px;
                height: 35px;
                
            }
        }
    }
    // 术语表
    .glossary-container{
        .glossary-header{
            height:40px;
            padding: 0 20px;
            text-align: left;
            font-weight: 600;
            color: #fff;
            line-height: 40px;
            background-color: #006494;
        }
        .close{
            width: 40px;
            height: 40px;
            background-color: #ffffff00;
            color: #fff;
        }
        .glossary-content{
            text-align: left;
            // 添加术语
            .content-header{
                padding: 15px 20px;
                background-color: #f2f2f2;
                position: relative;
                .btns{
                    position: absolute;
                    right: 20px;
                    display: flex;
                    gap: 10px;
                    button{
                        cursor: pointer;
                    }
                }
                .header-label{
                    margin-bottom: 20px;
                    color: #006494;
                    font-size: 14px;
                    font-weight: 600;
                }
                .inner-item{
                    margin-bottom: 15px;
                    display: flex;
                    font-size: 14px;
                    background-color: #fff;
                    .label{
                        width: 60px;
                        height: 35px;
                        border: 1px solid #DAE1E8;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .inner{
                        width: 100%;
                        border: 1px solid #DAE1E8;
                        border-left: none;
                        input{
                            width: 100%;
                            height: 100%;
                            padding: 0 10px;
                            font-size: 14px;
                            box-sizing: border-box;
                            border: none;
                            &:focus{
                                outline: none;
                            }
                            &::placeholder{
                                color: #b5bac2;
                            }
                        }
                    }
                }
                .btn{
                    text-align: right;
                    button{
                        width: 100px;
                        height: 30px;
                    }
                }
            }
            // 术语列表
            .content-list{
                height: 300px;
                padding: 15px 20px;
                overflow-y: auto;
                .list-label{
                    font-weight: 500;
                }
                .line{
                    width: 100%;
                    height: 1px;
                    margin: 12px 0;
                    background-color: #d1d2d4;
                }
                .list-item{
                    display: flex;
                    align-items: center;
                    .item-box{
                        width: calc((100% - 15%) / 2);
                        input{
                            width: 100%;
                            padding: 5 10px;
                            font-size: 14px;
                            box-sizing: border-box;
                            border: 1px solid #000;
                            &:focus{
                                outline: none;
                            }
                        }
                    }
                    .btn{
                        cursor: pointer;
                    }
                }
                .textarea{
                    width: 100%;
                    min-height: 100px;
                    margin: 8px 0;
                    padding: 10px;
                    box-sizing: border-box;
                    overflow-y: auto;
                    resize: none;
                    border-radius: 6px;
                    border-color: #d9d9d9;
                    color: rgba(0, 0, 0, 0.88);
                    font-size: 14px;
                }
            }
        }
    }
}

.Editor-preview{
    position: relative;
    .find-replace{
        padding: 5px;
        background-color: #1d1d1d;
        border: 1px solid #2f2f2f;
        position: absolute;
        top: 33px;
        right: 0;
        z-index: 999;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        .flex{
            display: flex;
            gap: 7px;
            button{
                width: 23px;
                height: 23px;
                padding: 0;
                border-radius: 3px;
                border: none;
                font-size: 14px;
                color: #fff;
                background-color: #ffffff00;
                cursor: pointer;
                &:hover{
                    background-color: #3a3b3c;
                }
            }
            .active{
                background-color: #2d4f7d;
                border: 1px solid #3476d3;
            }
        }
        input{
            padding: 2px 5px;
            color: #c4c4c4;
            border: 1px solid #404040;
            border-radius: 3px;
            background-color: #252525;
            &:focus{
                outline: none;
                border-color: #2566cb;
            }
        }
        .total{
            font-size: 14px;
            line-height: 23px;
        }
    }
    .loading{
        width: 100%;
        height: 100%;
        background-color: #00000010;
        position: absolute;
        top: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        .loading-box{
            width: 50px;
            height: 50px;
            border: 5px solid #ccc;
            border-top-color: #000;
            border-radius: 50%;
            animation: spin 1s infinite linear;
        }
    }
}
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.modal, .modal-operate{
    position: fixed;
    z-index: 2;
    display: flex;
    .modal-content{
        width: 200px;
        height: auto;
        display: flex;
        flex-direction: column;
        background-color: rgba($color: #000000, $alpha: 0.8);
        color: #fff;
        border-radius: 5px;
        overflow: hidden;
        button{
            padding: 10px 0;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            border: none;
            background-color: #ffffff00;
            cursor: pointer;
            transition: background-color 0.2s, color 0.2s;
            &:hover{
                background-color: #878686;
                // color: #000;
            }
        }
    }
    .modal-replace{
        width: 300px;
        height: auto;
        padding: 20px;
        background-color: rgba($color: #000000, $alpha: 0.8);
        color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .loading-box{
            width: 25px;
            height: 25px;
            border: 5px solid #ccc;
            border-top-color: #000;
            border-radius: 50%;
            animation: spin 1s infinite linear;
        }
        .btns{
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            button{
                padding: 3px 15px;
            }
        }
    }
}

.custom-highlight{
    background-color: #5b321a;
    color: #d6d8a2 !important;
}
.custom-highlight-select{
    background-color: #8e6316;
    color: #d7d9a3 !important;
}

.linkImport{
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.linkImport:hover{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.linkImport[disabled]{
    background-color: #fff;
    border-color: #ccc;
    pointer-events: none;
    cursor: not-allowed;
    box-shadow: none;
    opacity: .65;
}

.translate {
    position: absolute;
    right: 94px;
    top: 28px;
    z-index: 10;
    border: 1px solid #e7e7e7;
    background-color: #fff;
}

.translate div {
    cursor: pointer;
    padding: 5px 12px;
}

.translate div:hover {
    background-color: #ededed;
}