.switch {
    width: 54px;
    height: 24px;
    border-radius: 15px;
    background-color: #ccc;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .switch.on {
    background-color: #5cb85c;
  }
  
  .switch.off {
    background-color: #bfbfbf;
  }
  
  .slider {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 0.3s;
  }
  
  .switch.on .slider {
    transform: translateX(30px);
  }
  